<script>
  import { shownModal, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiTrophy } from '@mdi/js';
  import { isCamp, nanowrimoTrialTime } from '../nanowrimo';

  $: nanoTime = $nanowrimoTrialTime;
  $: isChallengeOpen = !$isCamp;

  function openInvite() {
    $shownModal = 'nanowrimoInvite';
  }
</script>

{#if nanoTime}
  <button class="dropdown-item" on:click={openInvite}>
    <Icon path={mdiTrophy} />{isChallengeOpen ? $t('nanowrimo_menu') : $t('nanowrimo_menu_camp')}
  </button>
{/if}
