<script>
  import { getUrl, projectStore, router, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
  import { getSiblings, mergeDocId, mergeScenes } from '../join-scenes';

  function close() {
    $mergeDocId = '';
  }

  function isFirst() {
    let children = getSiblings($mergeDocId);
    return children.indexOf($mergeDocId) === 0;
  }

  function isLast() {
    let children = getSiblings($mergeDocId);
    return children.indexOf($mergeDocId) === children.length - 1;
  }

  function mergeAbove() {
    let bottomId = $mergeDocId;
    let children = getSiblings($mergeDocId);
    let topId = children[children.indexOf(bottomId) - 1];
    merge(topId, bottomId);
  }

  function mergeBelow() {
    let topId = $mergeDocId;
    let children = getSiblings($mergeDocId);
    let bottomId = children[children.indexOf(topId) + 1];
    merge(topId, bottomId);
  }

  function merge(topId, bottomId) {
    mergeScenes(topId, bottomId);
    router.navigate(getUrl(topId));
    close();
  }

  function isScene(direction) {
    let children = getSiblings($mergeDocId);
    let otherSceneId = direction === 'up' ? children[children.indexOf($mergeDocId) - 1] : children[children.indexOf($mergeDocId) + 1];
    const doc = projectStore.getDoc(otherSceneId);

    return doc.type === 'novel_scene';
  }

  function canMergeAbove() {
    return !isFirst() && isScene('up');
  }

  function canMergeBelow() {
    return !isLast() && isScene('down');
  }
</script>

{#if $mergeDocId}
  <Modal title={$t('novel_merge_scene')} on:close={close}>
    <section class="goals-settings-main">
      <div class="section-flex">
        <div>{$t('novel_scenes_merge_which')}</div>
      </div>
    </section>
    <footer>
      <button class="btn secondary" on:click={close}>{$t('cancel')}</button>
      <button class="btn primary" on:click={mergeAbove} disabled={!canMergeAbove()}>
        <Icon path={mdiArrowUp} />
        {$t('novel_scenes_merge_above')}
      </button>
      <button class="btn primary" on:click={mergeBelow} disabled={!canMergeBelow()}>
        <Icon path={mdiArrowDown} />
        {$t('novel_scenes_merge_below')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  .section-flex {
    display: flex;
  }
  .section-flex > :not(:first-child) {
    margin-left: 40px;
  }
  :global(.size-mobile) .section-flex {
    flex-direction: column;
  }
  :global(.size-mobile) .section-flex > :not(:first-child) {
    margin-left: 0;
    margin-top: 40px;
  }
</style>
