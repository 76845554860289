import { format, h } from 'typewriter-editor';
import './highlights.css';

let lastHighlight = 'yellow';
const colors = new Set(['yellow', 'green', 'blue', 'purple', 'teal', 'black']);
/*
Highlight values for word docs if we ever export them:
default
black = black
blue = blue
green = green
darkBlue
darkRed
darkYellow
lightGray
darkGray
darkGreen
magenta
red
darkCyan = teal
cyan
darkMagenta = purple
white
yellow = yellow
*/

format({
  name: 'highlight',
  selector: 'format-highlight',
  fromDom: (node: HTMLElement) => node.className.split(/\s+/).find(c => colors.has(c)),
  commands: editor => (highlight: string) => {
    if (highlight === undefined) {
      highlight = lastHighlight;
      editor.toggleTextFormat({ highlight });
    } else {
      lastHighlight = highlight;
      editor.formatText({ highlight });
    }
  },
  shortcuts: 'Ctrl+Alt+H',
  render: (attributes, children) => h('format-highlight', { class: `${attributes.highlight}` }, children),
});

format({
  name: 'del',
  selector: 's,del',
  commands: editor => () => editor.toggleTextFormat({ del: true }),
  shortcuts: 'Mod+D',
  render: (attributes, children) => h('s', null, children),
});
